import React from 'react'
import { isApp } from 'pages/embed'
import { Button } from 'nzk-react-components'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Layout from '../../layouts/layout'
import ReferAFriendPage from '../../components/pages/ReferAFriendPage'

const UnavailableContent = styled.div`
  color: #fff;
  text-align: center;
  padding-top: 20px;

  h2 {
    font-family: 'Rammetto One';
    font-weight: normal;
  }
`

const FeaturesPageWrapper = () => {
  if (typeof window === 'undefined') return null

  return (
    <Layout>
      {!isApp() && <ReferAFriendPage />}
      {isApp() && (
        <UnavailableContent>
          <h2>This page is unavailable on the app.</h2>
          <div>
            <Button
              theme="primary"
              size="small"
              onClick={() => {
                navigate('/')
              }}
            >
              Back
            </Button>
          </div>
        </UnavailableContent>
      )}
    </Layout>
  )
}

export default FeaturesPageWrapper
